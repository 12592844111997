import React, {useState, useEffect} from 'react'
import './Home.scss'
import {CTAmobileCard, CTAsupportSnow} from '../UI Kit/CTA/CTA.js'
import {H1, H2, H3, H4, P1, P2, X1} from "../UI Kit/Text/Text"

import handImage from '../../assets/img/hand_touch_blue.jpg'
import runningMan from '../../assets/img/polygon_running_man.jpg'
import polygonEarth from '../../assets/img/polygon_earth_hand.jpg'
import glowImageSnow from '../../assets/img/blue_light_glow_&_logo_snow_rounded.png'
import electricHand from '../../assets/img/polygon_eletric_hand.jpg'

const screenWidth = window.innerWidth;

const Home = () => {



    const [desktopSectionId, setDesktopSectionId] = useState('');
    const [mobileSectionId, setMobileSectionId] = useState('');

    useEffect(() => {

        if(screenWidth > 1024){
            setDesktopSectionId('section_services')
            setMobileSectionId('')
        }else{
            setDesktopSectionId('')
            setMobileSectionId('section_services')
        }

    } , [])


    return (
        <div className="home" id='section_home'>

            <div className={'mainContainer'}>

                {/*HERO _________________________________*/}
                <div className={'hero'}>

                    <div className="innerHero">

                        <X1 text={'- explore new capabilities'} className={'hero_x1'}/>

                        <H1
                            text={'Reprenez le  contrôle d\'un seul geste.'}
                            style={{margin: '16px 0 30px 0'}}
                            className={'hero_h1'}
                        />

                        <H1
                            text={'Reprenez le \ncontrôle d\'un\nseul geste.'}
                            style={{margin: '16px 0 30px 0'}}
                            className={'hero_h1 hero_h1_mobile'}
                        />

                        <P1 text={'Snow, l\'avenir de la domotique haut de gamme est déjà là.'} className={'mobile_hidden'}/>

                        <CTAsupportSnow style={{marginTop: '32px'}} type={'primary'}  className={'mobile_hidden'}/>

                    </div>

                    <img src={handImage} alt="" className={'heroImage'}/>


                    <CTAsupportSnow style={{marginTop: '32px'}} type={'primary'}  className={'mobile_only hero_cta'}/>





                </div>

                <H4 text={'Bientôt plus de 100 objets compatibles.'} className={'mobile_hidden'}/>

                <H4 text={'Bientôt plus de 100\nobjets compatibles.'} className={'mobile_only'}/>























                {/*CARD #1 _________________________________*/}
                <div className={'card card_top'}>

                    <div className={'innerCard'}>

                        <H2 text={'Une solution innovante et disruptive'} />

                        <P1
                            text={'Une reconnaissance de geste sans égal sur le marché.'}
                            style={{margin: '7px 0 15px 0'}}
                            className={'p1_card'}
                        />

                        <CTAsupportSnow type={'primary'} className={'mobile_hidden'}/>

                        <CTAmobileCard type={'primary'}/>

                    </div>
                    
                    <img
                        src={runningMan}
                        alt=""
                        // style={{position: "absolute", top: '10%', right: '3.3%'}}
                        className={'image_container_glow first_image_card mobile_hidden'}
                    />

                    <section id={desktopSectionId} className='midSection mobile_hidden'></section>


                </div>

                <div className="mobile_img_container mobile_only">
                    <img
                        src={runningMan}
                        alt=""
                        className={'image_container_glow first_image_card mobile_img_running'}
                    />
                </div>














                {/*CONTENT #1 _________________________________*/}
                <div className={'content1'} >

                    <section id={mobileSectionId} className='mobile_only' style={{position: "relative", top: "-40em"}}></section>



                    <img src={polygonEarth} alt="" style={{}} className={'image_container_glow'} />
                    {/*<img src={glowImageOld} alt="" style={{}}  />*/}


                    <div className={'content_container'}>

                        <X1 text={'Fluidité'} />

                        <H3
                            text={'Un contrôle naturel et intuitif'}
                            style={{margin: '4px 0 5px 0'}}
                        />

                        <P2
                            text={'Snow souhaite réinventer la manière dont vous interagissez avec ' +
                                'votre environnement. Grâce à Snow, vous pouvez désormais contrôler ' +
                                'tous vos objets connectés en utilisant des gestes naturels. Éteignez ' +
                                'les lumières d\'un simple mouvement de la main, réglez votre système ' +
                                'audio sans toucher à aucun bouton. Snow vous offre une expérience ' +
                                'fluide et intuitive, pour une vie connectée sans effort.'}
                            className={'content_text1'}
                        />

                        <CTAsupportSnow type={'secondary'} style={{marginTop: '0.7em'}} className={'content_cta'}/>

                    </div>



                </div>

                {/*CONTENT #2 _________________________________*/}
                <div className={'content2'}>

                    <div className={'content_container'}>

                        <X1 text={'UX'} />

                        <H3
                            text={'Une expérience unique au monde'}
                            style={{margin: '4px 0 5px 0'}}
                        />

                        <P2
                            text={'Plongez dans une expérience hors du commun avec notre ' +
                                'produit exceptionnel. Grâce à une technologie de pointe, nous ' +
                                'vous offrons une interaction inégalée avec votre environnement ' +
                                'connecté. Contrôlez vos objets avec des gestes intuitifs, vivez ' +
                                'une expérience immersive et découvrez un monde où la ' +
                                'technologie se fond parfaitement dans votre quotidien. ' +
                                'Bienvenue dans une expérience sans pareille.'}
                            className={'content_text2'}
                        />

                        <CTAsupportSnow type={'secondary'} style={{marginTop: '0.7em'}} className={'content_cta'}/>

                    </div>



                    <img src={glowImageSnow} alt="" style={{}} className={'image_container_glow'}/>
                    {/*<img src={glowImageOld} alt="" style={{}} />*/}



                </div>




                {/*CARD #2 _________________________________*/}
                <div className={'card card_bottom'}>

                    <div className={'innerCard'}>

                    <H2 text={'Embarquez dans l\'univers Snow dès aujourd\'hui'} />

                    <P1
                        text={'Ne ratez pas le prochain stade de l\'évolution de la domotique.'}
                        style={{margin: '7px 0 15px 0'}}
                        className={'p1_card p2_card'}
                    />

                    <CTAsupportSnow type={'primary'} className={'mobile_hidden'}/>

                    <CTAmobileCard type={'primary'}/>

                    </div>


                    <img
                        src={electricHand}
                        alt=""
                        // style={{position: "absolute", top: '10.6%', right: '3.5%'}}
                        className={'image_container_glow second_image_card mobile_hidden'}
                    />



                </div>

                <div className="mobile_img_container mobile_only">
                    <img
                        src={electricHand}
                        alt=""
                        className={'image_container_glow mobile_img_running'}
                    />
                </div>







            </div>



        </div>
    );
};

export default Home;
