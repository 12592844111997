// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import './App.scss';
//
// import Header from './components/Header/Header';
// import Home from './components/Home/Home';
// import Services from './components/Services/Services';
// import AboutUs from './components/About/About';
// import Contact from './components/Contact/Contact';
// import Footer from './components/Footer/Footer';
//
// function App() {
//     return (
//         <div className="App">
//             <Router>
//                 <Header />
//                 <Routes>
//                     <Route path="/" element={<Home />} />
//                     <Route path="/services" element={<Services />} />
//                     <Route path="/about-us" element={<AboutUs />} />
//                     <Route path="/contact" element={<Contact />} />
//                 </Routes>
//                 <Footer />
//             </Router>
//         </div>
//     );
// }
//
// export default App;

import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
// import Services from './components/Services/Services';
import Contact from './components/Contact/Contact';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import './App.scss';

function App() {

    const [activeSection, setActiveSection] = useState('section_home');

    return (
        <Router>
            <div className="App">
                <Navbar activeSection={activeSection} setActiveSection={setActiveSection} />
                <Routes>
                    <Route path="/" element={<Home />} />
                    {/*<Route path="/services" element={<Services />} />*/}
                    <Route path="/contact" element={<Contact />} />
                </Routes>
                <Footer activeSection={activeSection} setActiveSection={setActiveSection} />
            </div>
        </Router>
    );
}

export default App;
