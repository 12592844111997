import React, {useCallback, useState} from 'react';
import './Navbar.scss';
import {CTAsupportSnow} from "../UI Kit/CTA/CTA";

import smoothscroll from 'smoothscroll-polyfill';
import menu_icon_closed from "../../assets/icon/burger_menu_icon_closed.png";
import menu_icon_opened from "../../assets/icon/burger_menu_icon_opened.png";

smoothscroll.polyfill();

const screenWidth = window.innerWidth;

export const scrollToSection = (event, sectionId) => {

    event.preventDefault();

    const targetSection = document.getElementById(sectionId);
    if (targetSection) {
        targetSection.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        })

    }


}



const Navbar = ({ activeSection, setActiveSection }) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [backdropVisibility, setBackdropVisibility] = useState('backdrop_hidden');

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        if(screenWidth < 1024){
            toggleBackdrop();
        }
    };

    // eslint-disable-next-line
    const toggleMenuWithDelay = () => {
        setTimeout(() => {
            toggleMenu();
        }, 800)
    };


    const toggleBackdrop = () => {
        if (backdropVisibility === 'backdrop_hidden') {
            setBackdropVisibility('backdrop_visible');
        } else {
            setBackdropVisibility('backdrop_hidden');
        }
    }



    // const toggleMenu = useCallback(
    //     () => {
    //         setIsMenuOpen(!isMenuOpen);
    //         toggleBackdrop();
    //     },
    //     [isMenuOpen, toggleBackdrop, setIsMenuOpen]
    // );



    // const toggleMenuWithDelay = useCallback(
    //     () => {
    //         setTimeout(() => {
    //             toggleMenu();
    //         }, 800)
    //     },
    //     [toggleMenu]
    // );





    // const [activeSection, setActiveSection] = useState('section_home');


    const handleScrollToSection = useCallback(
        (event, sectionId) => {
            toggleMenuWithDelay();
            scrollToSection(event, sectionId)
            setActiveSection(sectionId)
        },
        [setActiveSection, toggleMenuWithDelay]
    );


    return (
        <header className="nav-header">

            <div className={backdropVisibility}></div>

            <nav className="navbar">
                <div className="left-header">

                    <p
                        className="navbar-logo"
                        onClick={(e) => handleScrollToSection(e, 'section_home')}
                    >
                        Snow
                    </p>
                    <ul className="navbar-menu">
                        <li className="navbar-item">
                            <p
                                className={`navbar-link ${activeSection === 'section_home' ? 'active' : ''}`}
                                // onClick={(e) => scrollToSection(e, 'section_home', setActiveSection)}
                                onClick={(e) => handleScrollToSection(e, 'section_home')}
                            >
                                Accueil
                            </p>
                        </li>
                        <li className="navbar-item">
                            <p
                                className={`navbar-link ${activeSection === 'section_services' ? 'active' : ''}`}
                                onClick={(e) => handleScrollToSection(e, 'section_services')}
                            >
                                Services
                            </p>
                        </li>
                        <li className="navbar-item">
                            <p
                                className={`navbar-link ${activeSection === 'section_contact' ? 'active' : ''}`}
                                onClick={(e) => handleScrollToSection(e, 'section_contact')}
                            >
                                Contact
                            </p>
                        </li>
                    </ul>

                </div>

                <CTAsupportSnow className={'navbar-cta'} />

                <img
                    src={isMenuOpen ? menu_icon_opened : menu_icon_closed}
                    alt="Menu Icon"
                    className={`menu_icon ${isMenuOpen ? 'open' : ''}`}
                    onClick={toggleMenu}
                />
            </nav>

            {isMenuOpen && (
                <div className="mobile-menu">
                    <ul className="mobile-menu-item-container">
                        <li className="mobile-menu-item">
                            <p
                                onClick={(e) => handleScrollToSection(e, 'section_home')}
                                className={`navbar_link_mobile ${activeSection === 'section_home' ? 'active' : ''}`}
                            >
                                Accueil
                            </p>
                        </li>
                        <li className="mobile-menu-item">
                            <p
                                onClick={(e) => handleScrollToSection(e, 'section_services')}
                                className={`navbar_link_mobile ${activeSection === 'section_services' ? 'active' : ''}`}
                            >
                                Services
                            </p>
                        </li>
                        <li className="mobile-menu-item">
                            <p
                                onClick={(e) => handleScrollToSection(e, 'section_contact')}
                                className={`navbar_link_mobile ${activeSection === 'section_contact' ? 'active' : ''}`}
                            >
                                Contact
                            </p>
                        </li>
                    </ul>
                </div>
            )}

        </header>
    );
};


export default Navbar;
