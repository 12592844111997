import React from 'react';
import './Text.scss';



//Hero title
export const H1 = ({ text, style, className, href}) => {
    const formattedText = text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));

    return <a href={href} className={`h1 ${className}`} style={style}>{formattedText}</a>;
}


//Card title
export const H2 = ({ text, style, className, href}) => {
    const formattedText = text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));

    return <a href={href} className={`h2 ${className}`} style={style}>{formattedText}</a>;
}


//Content title
export const H3 = ({ text, style, className, href}) => {
    const formattedText = text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));

    return <a href={href} className={`h3 ${className}`} style={style}>{formattedText}</a>;
}


//Hero bottom big text
export const H4 = ({ text, style, className, href}) => {
    const formattedText = text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));

    return <a href={href} className={`h4 ${className}`} style={style}>{formattedText}</a>;
}


//Grey CAPITAL text
export const X1 = ({ text, style, className, href}) => {
    const formattedText = text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));

    return <a href={href} className={`x1 ${className}`} style={style}>{formattedText}</a>;
}


//Card text
export const P1 = ({ text, style, className, href}) => {
    const formattedText = text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));

    return <a href={href} className={`p1 ${className}`} style={style}>{formattedText}</a>;
}


//Content text
export const P2 = ({ text, style, className, href}) => {
    const formattedText = text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));

    return <a href={href} className={`p2 ${className}`} style={style}>{formattedText}</a>;
}


//Footer links text
export const L1 = ({ text, style, className, onClick, href}) => {
    const formattedText = text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));

    return <a href={href} target="_blank" rel="noopener noreferrer" onClick={onClick} className={`l1 ${className}`} style={style}>{formattedText}</a>;
}


