import React, {useEffect, useState} from 'react';


import './CTA.scss';



const CTA = ({text, style, className, type, onClick, onClickUrlOpen}) => {

    const [specifiClassContainer, setSpecifiClassContainer] = useState('primaryContainer');
    const [specifiClassText, setSpecifiClassText] = useState('primaryText');

    useEffect(() => {

        switch (type) {
            case 'primary':
                setSpecifiClassContainer('primaryContainer');
                setSpecifiClassText('primaryText');
                break;
            case 'secondary':
                setSpecifiClassContainer('secondaryContainer');
                setSpecifiClassText('secondaryText');
                break;
            case 'tertiary':
                setSpecifiClassContainer('tertiaryContainer');
                setSpecifiClassText('tertiaryText');
                break;
            default:
                setSpecifiClassContainer('primaryContainer');
                setSpecifiClassText('primaryText');
                break;
        }

    }, [type] );

    useEffect(() => {
        if (className && className.includes('mobilePrimaryContainer')) {
            setSpecifiClassText(specifiClassText=> specifiClassText + ' mobilePrimaryText')
        }
    }, [className]);


    if(onClickUrlOpen){
        onClick = () => window.open(onClickUrlOpen, '_blank')
    }


    return (
        <div className={`CTA_container ${className} ${specifiClassContainer} `} style={style} onClick={onClick}>

            <p className={`CTA_text ${specifiClassText}`} >{text}</p>

            {/*<a href={href} target="_blank" rel="noopener noreferrer" className={`CTA_text ${specifiClassText}`} >{text}</a>*/}

        </div>
    )
}

export const CTAsupportSnow =  ({type, style, className}) => {

    return (
        <CTA
            text={'Supporter Snow'}
            type={type}
            onClickUrlOpen={'https://www.linkedin.com/company/snow-smart'}
            style={style}
            className={className}
        />
    )
}

export const CTAmobileCard =  ({type, style, className}) => {

    return (
        <CTA
            text={'Supporter Snow'}
            type={type}
            onClickUrlOpen={'https://www.linkedin.com/company/snow-smart'}
            style={style}
            className={`mobilePrimaryContainer ${className}`}
        />
    )
}




export default CTA;
